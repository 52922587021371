
import { defineComponent } from "vue";
import base from "@/api/base.js";
import XLSX from "xlsx";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ElConfigProvider from "element-plus";
import ko from "element-plus/lib/locale/lang/ko";

export default defineComponent({
  name: "result-detail",
  components: { VPagination, ElConfigProvider },
  props: {},
  data() {
    return {
      results: [{ store: { cmp: {}, brand: {} } }],
      allResult: [],
      searchcode: "brand_nm",
      searchdata: "",
      dueDatePickerOptions: {
        disabledDate: this.disabledDueDate,
      },
      startDate: "",
      endDate: "",
      checkItems: [],
      searchItem: {},
      allSelected: "false",
      page: 1,
      ko,
    };
  },
  created() {
    this.getListData();
  },
  computed: {},
  methods: {
    disabledDueDate(time) {
      return time.getTime() < this.startDate;
    },

    getSearchResult() {
      this.page = 1;
      this.getListData();
    },
    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        start_date: this.startDate,
        end_date: this.endDate,
        check_item_title: this.searchItem.id,
        page: this.page,
      };
      base
        .getResultDetail(param)
        .then((res) => {
          console.log(res);
          this.results = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
      base
        .getResultDetail()
        .then((res) => {
          console.log(res);
          this.allResult = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getAllList() {
      base
        .getResultDetail()
        .then((res) => {
          console.log(res);
          this.allResult = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    excelDownFunc() {
      this.excelCheckItem();
      const ws = XLSX.utils.json_to_sheet(this.allResult);
      const wb = XLSX.utils.book_new();
      ws.A1.v = "번호";
      ws.B1.v = "매장명";
      ws.C1.v = "점검유형";
      ws.D1.v = "점검일";
      ws.E1.v = "점겅항목";
      ws.F1.v = "점수";
      ws.G1.v = "점검자";
      ws.H1.v = "답변";
      ws.I1.v = "브랜드";
      XLSX.utils.book_append_sheet(wb, ws, "QSC_ResultData");
      XLSX.writeFile(wb, "result.xlsx");
    },

    excelCheckItem() {
      const newitem = this.allResult.map((e) => {
        e.brand = e.store.brand.brand_nm;
        e.store = e.store.store_nm;
        return e;
      });
    },

    findData() {
      const checkSearch = {
        check_item_title: this.checkitemSearch,
      };
      base
        .getCheckItemList(checkSearch)
        .then((res) => {
          console.log(res);
          this.checkItems = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    selectAll() {
      this.itemIds = [];
      if (this.allSelected) {
        for (const item in this.checkItems) {
          this.itemIds.push(this.checkItems[item].id);
        }
      }
    },

    resetModal() {
      (this.searchItem = {}), (this.checkItems = []);
      this.checkitemSearch = "";
    },
  },
});
